window.FileCompare = Backbone.View.extend({
    el: ".compare-wrap",
    events: {
        "change input.from_file": "fromFileChange",
        "change input.to_file": "toFileChange"
    },

    initialize(opts) {
        var self = this

        this.$(".from_upload_file").on("click", function (e){
            if(!$.isNumeric(self.$(".from_upload_file").data("user-id"))) {
                $("#loginModay").modal("show")
                return
            }
            self.fromChoseUploadFile(e)
        })

        this.$(".to_upload_file").on("click", function (e){
            self.toChoseUploadFile(e)
        })

        this.$('.compare-form').on('ajax:beforeSend', function() {
            self.$('.compare-btn').prop('disabled', true).text('处理中...')
        });

        this.$('.compare-form').on('ajax:complete', function() {
            self.$('.compare-btn').prop('disabled', false).text('比较');
        });
    },

    fromChoseUploadFile(e) {
        this.$("input.from_file").click()
    },

    toChoseUploadFile(e) {
        this.$("input.to_file").click()
    },

    fromFileChange(e) {
        var files = this.$("input.from_file")[0].files

        if (files.length > 0) {
            this.$(".from-file-name").html(files[0].name)
        }
    },

    toFileChange(e) {
        var files = this.$("input.to_file")[0].files

        if (files.length > 0) {
            this.$(".to-file-name").html(files[0].name)
        }

        self.$('.compare-btn').prop('disabled', false)
    }
});
