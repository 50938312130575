window.FilesConvert = Backbone.View.extend({
    el: ".files-editor-toolbar",
    events: {
        // "click #btn_cancel": "cancelConvert",
        // "click #btn_do": "doConvert",
        // "click #re-upload": "doReUpload"
        "click .setting_options .btn_setting" : "toggleOptionWrap"
    },

    initialize(opts) {
        var self = this

        var val = self.$('.btn_do_convert').text()
        if($(this.el).hasClass("required-login") && !$.isNumeric($(this.el).data("user-id"))) {
            this.$('.convert-form').on("click", function (e){
                e.preventDefault()
                $("#loginModay").modal("show")
            })
        }

        this.$('.convert-form').on('ajax:beforeSend', function() {
            self.$('.btn_do_convert').prop('disabled', true).text('提交中...')
        });

        this.$('.convert-form').on('ajax:complete', function() {
            self.$('.btn_do_convert').prop('disabled', false).text(val);
        });

        var imgMimTypeJson = {
            "default": ['jpg','pdf', 'png', 'pnm', 'svg', 'tiff', 'webp'],
            "image/gif": ['jpg','pdf', 'png', 'pnm', 'svg', 'tiff', 'webp'],
            "image/jpeg": ['jpg','pdf', 'png', 'pnm', 'svg', 'tiff', 'webp'],
            "image/jpg": ['gif','pdf', 'png', 'pnm', 'svg', 'tiff', 'webp', 'jxl'],
            "image/png": ['pdf', 'pnm', 'svg', 'tiff', 'webp'],
            "psd": ['png','pnm','svg','jpg', 'tiff', 'webp'],
            "image/x-icon": ['jpg','pdf', 'png', 'pnm', 'svg', 'tiff', 'webp'],
            "image/vnd.microsoft.icon": ['jpg','pdf', 'png', 'pnm', 'svg', 'tiff', 'webp'],
        }

        this.$("input.img-to").on('change', function (event) {
            const file = event.target.files[0];

            if (file) {
                minType = file.type.toLowerCase()
                var items = imgMimTypeJson[minType]
                if(items == null) {
                    items = imgMimTypeJson["default"]
                }
                self.$("#convert_ext").empty();
                for (var i = 0;i<items.length; i++) {
                    self.$("#convert_ext").append("<option value='"+ items[i] +"'>"+ items[i] +"</option>")
                }
            }
        })


        this.$("input:file").on('change', function (event) {
            const file = event.target.files[0];
            if (file) {
                let fileName = file.name.toLowerCase()
                let input_format = self.getFileExtension(fileName)
                if($(self.el).hasClass("converter_fetch_output")) {
                    self.$("#convert_ext").empty();
                    let items = []
                    for (var i = 0; i < converter_config.length; i++) {
                        if (converter_config[i].input_format == input_format) {
                            items.push(converter_config[i])
                            let output = converter_config[i].output_format
                            self.$("#convert_ext").append("<option value='" + output + "'>" + output + "</option>")
                        }
                    }
                }

                let output_format = self.$("select.out-format-ext").val()
                if($(self.el).hasClass("converter_fetch_optioins")) {
                    let exclude_list = window.converter_exclude_options_ext_list || []
                    if (!exclude_list.includes(output_format)) {
                        self.loadConvertOption(input_format, output_format)
                    } else {
                        self.$(".setting_options_content").empty();
                    }
                }

                let engine_map = window.converter_ext_engine_map || {}
                if (Object.keys(engine_map).length > 0) {
                    if(engine_map[output_format] != null && engine_map[output_format] !== undefined) {
                        self.$("input[name='engine']").val(engine_map[output_format])
                    }else {
                        self.$("input[name='engine']").val(engine_map["default"])
                    }
                }
            }
        })

        this.$("select.out-format-ext").on('change', function (event) {
            const file = self.$("input:file")[0].files[0]
            if (file) {
                let fileName = file.name.toLowerCase()
                let input_format = self.getFileExtension(fileName)
                let output_format = self.$("select.out-format-ext").val()
                if($(self.el).hasClass("converter_fetch_optioins")) {
                    let exclude_list = window.converter_exclude_options_ext_list || []
                    if (!exclude_list.includes(output_format)) {
                        self.loadConvertOption(input_format, output_format);
                    } else {
                        self.$(".setting_options_content").empty();
                    }
                }

                let engine_map = window.converter_ext_engine_map || {}
                if (Object.keys(engine_map).length > 0) {
                    if(engine_map[output_format] != null && engine_map[output_format] !== undefined) {
                        self.$("input[name='engine']").val(engine_map[output_format])
                    }else {
                        self.$("input[name='engine']").val(engine_map["default"])
                    }
                }
            }
        })

        return this.initDropzone();
    },
    doReUpload(e){
        this.$(".upload").show()
        this.$(".download").hide()
        return false
    },
    cancelConvert(e) {
        this.$(".upload").show()
        this.$(".convert").hide()
        return false
    },

    loadConvertOption(input_format, output_format) {
        this.$('.btn_do_convert').prop('disabled', true)
        var self = this
        $.ajax({
            url: '/files/convert_options?input_format='+ input_format + "&output_format="+ output_format,
            type: 'GET',
            dataType: 'script',
            complete(xhr, status) {
                self.$('.btn_do_convert').prop('disabled', false)
            }
        });
    },
    toggleOptionWrap() {
        this.$(".setting_options_wrap").toggle();
    },

    getFileExtension(filename) {
        // 使用正则表达式匹配文件扩展名
        const match = /\.([0-9a-z]+)(?:[\?#]|$)/i.exec(filename);
        // 如果有匹配结果，返回扩展名，否则返回空字符串
        return match ? match[1] : '';
    },

    doConvert(e) {
        let btnDo = this.$("#btn_do")
        let documentID = btnDo.data("document-id")
        btnDo.attr("disabled", true)
        let self = this;

        $.ajax({
            type: "post",
            url: "/documents/"+ documentID +"/convert",
            data: {
                "to_format": btnDo.data("to-format"),
            },
            dataType: "json",
            success(res) {
                self.$(".convert").hide()
                self.$(".upload").hide()
                self.$(".download").show()
                self.$(".download a").attr("href", res.path)
            },
            complete(xhr,status){
                btnDo.removeAttr("disabled")
            },
            error(temp, msg) {
                if (typeof msg === "string") {
                    // from client side
                    App.alert(msg);
                } else {
                    // from server side
                    App.alert(msg.message);
                }
            }
        })

        return false
    },

    initDropzone() {
        let dropzone;
        const self = this;

        (dropzone = this.$("#editor-upload").dropzone({
            url: "/documents",
            dictDefaultMessage: "",
            // clickable: ,
            paramName: "file",
            maxFilesize: 20,
            uploadMultiple: false,
            // acceptedFiles: "docx/*",
            headers: {
                "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            previewContainer: false,
            processing() {
            },
            dragover() {
            },
            dragleave() {
            },
            drop() {
            },
            success(header, res) {
                self.$("#btn_do").removeAttr("disabled").data("document-id", res.document_id)
            },
            error(temp, msg) {
                if (typeof msg === "string") {
                    // from client side
                    App.alert(msg);
                } else {
                    // from server side
                    App.alert(msg.message);
                }
            },
            totaluploadprogress(num) {},
            sending() {},
            queuecomplete() {
            },
        }));

        return dropzone
    }
});

window.FilesConvertChoose = Backbone.View.extend({
    el: ".convert_wrap",
    events: {
        "change .convert_choose select": "changeSource"
    },
    changeSource() {
        var from_source = $.trim(this.$("select.from_source").val())
        var to_source = $.trim(this.$("select.to_source").val())

        window.location.href = "/markup/"+ from_source + "-to-" + to_source
    }
})